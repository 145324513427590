@font-face {
  font-family: 'Yaldevi';
  src: url('fonts/Yaldevi-ExtraLight.ttf') format('truetype');
}

body {
  background-color: #141114;
  min-height: 100vh;
  font-family: "Yaldevi";
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 13px;
  }
}

.react-parallax {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-parallax-content {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('img/1.jpeg');
  background-position: center;
  background-size: cover;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffe259;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #ffa751, #ffe259);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #ffa751, #ffe259); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.footer a {
  color: unset;
}

.footer a svg {
  transition: all 0.3s ease-in 0.1s;
  filter: blur(.5px);
}

.footer a:hover svg {
  filter: blur(0px);
}

.footer p {
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .6em;
  gap: .5em;
}

.sign {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(
          ellipse 50% 35% at 50% 50%,
          #6b1839,
          transparent
  );
  letter-spacing: 2;
  font-family: "Yaldevi";
  font-size: 6em;
  color: #ffe6ff;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
  -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
  0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  animation: shine 2s forwards, flicker 3s infinite;
  white-space: nowrap;
}

.quote {
  color: #ffe6ff;
  text-align: center;
  font-size: 1.3em;
  margin-top: .5em;
}

.quote.animated span {
  filter: blur(0px)!important;
}


::selection {
  color: #343232;
  background: #b8b8b8;
}

::-webkit-scrollbar-track {
  width: 5px;
  border-radius: 2.5px;
  background-color: transparent
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 1.5px;
  background-color: rgba(158,158,158,.2)
}

::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 1.5px;
  background-color: rgba(158,158,158,.5)
}

.sign::selection, .sign span::selection {
  color: #343232;
  background: #ff65bd;
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

.flicker {
  animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  animation: shine 2s forwards, blink 10s 1s infinite;
}

.slow-flicker {
  animation: shine 2s forwards, blink 20s .6s infinite;
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
